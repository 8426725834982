'use client';

import type { ReactNode } from 'react';
import { getCookie } from 'cookies-next';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { publicConfig } from '~/config';
if (typeof document !== 'undefined') {
  const flags = getCookie('bootstrapData');
  const bootstrapData = (flags ? JSON.parse(flags.toString()) : {}) as Record<string, unknown>;
  posthog.init(publicConfig.posthog.key, {
    api_host: '/ingest',
    ui_host: 'https://app.posthog.com',
    capture_pageview: false,
    capture_pageleave: true,
    bootstrap: bootstrapData
  });
}
const PHProvider = ({
  children
}: Readonly<{
  children: ReactNode;
}>) => {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-provider.tsx">
			{children}
		</PostHogProvider>;
};
export default PHProvider;